import React from "react";
import LoginComponent from "../components/Login/Login";

function Login() {
  return (
    <div>
      <LoginComponent />
    </div>
  );
}

export default Login;
