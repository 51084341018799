import React from "react";
import RegisterComponent from "../components/RegisterComponent/RegisterComponent";

function Register() {
  return (
    <div>
      <RegisterComponent />
    </div>
  );
}

export default Register;
