import React from "react";
import Hero from "../components/Hero/Hero";

function Home() {
  return (
    <div>
      <Hero />
    </div>
  );
}

export default Home;
